.category-labels {
  @extend %reset;
  width: 100%;
  display: flex;
}

.category-label {
  @extend %typography__property;
  padding: 0.35em 0.5em;
  border: 1px solid black;
  border-radius: 0.45em;
}