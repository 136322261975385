%wp-content {
  h2 {
    @extend %typography__header2;
  }

  ul {
    margin-bottom: $whitespace--sibling * 2;
    color: $color--grey03;
    li {
      line-height: 1.6;
    }
  }

  a {
    @extend %color__link;
    text-decoration: none;
    &[href^="tel:"] {
      &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f879";
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }
  }
}

.default-view {
  padding: $whitespace--inner-block 2rem 6 * $whitespace--inner-block;
  &__title {
    @extend %typography__header1;
    & + * {
      margin-top: $whitespace--relation;
    }
  }
  &__content {
    @extend %typography__content;
    figure{
      @extend %reset--figure-element;
      margin: 1em 0;
    }
    img {
      max-width: 100%;
    }
  }
}

.default-view__content {
  @extend %wp-content;
}