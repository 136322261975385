.home-view {
  @extend .default-view;

  &__title {
    @extend .default-view__title;
  }

  &__user-name {
    @extend %reset;
    color: $color--green01;
    &:empty::before {
      @extend %placeholder;
      width: 6em;
    }
  }
}