.sub-navigation {
  & + * {
    margin-top: $whitespace--relation;
  }
  &__list {
    @extend %reset;
  }
  &__item {
    @extend %reset;
    @extend %typography__content;
    display: inline-block;
    white-space: nowrap;
    font-weight: 700;
    color: $color--reduced;
    &.active {
      color: $color--cta-contrast;
    }
    * + & {
      margin-left: $whitespace--relation;
    }
  }
}